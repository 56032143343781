import styled from 'styled-components';

export const Style = styled.div`
  /* display: inline-block; */
  right: 24px;
  bottom: 24px;
  width: 40px;
  height: 40px;
  display: flex;
  position: fixed;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background: var(--color_card);
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.12);

  .scroll-top-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    fill: var(--color_body_text);
  }

  &.show-scroll-top {
    transform: translateX(0px);
  }

  &.hide-scroll-top {
    transform: translateX(100px);
  }
`;
