import { useEffect } from 'react';
import { MessageType, FIXED_SECTIONS } from 'const';

function useKeyboardBind(isEdit, current) {
  const handleKeydown = (e) => {
    if (e.key === 'Backspace') {
      if (current && !FIXED_SECTIONS.includes(current)) {
        window.parent.postMessage(
          {
            type: MessageType.SECTION_DELETE,
            payload: {
              sectionHashId: current,
            },
          },
          '*',
        );
      }
    }
    if (e.metaKey && e.key === 's') {
      e.preventDefault();
      window.parent.postMessage(
        {
          type: MessageType.TEMPLATE_SAVE,
          payload: {},
        },
        '*',
      );
    }
  };
  useEffect(() => {
    // 编辑模式才走这个逻辑
    if (isEdit) {
      window.addEventListener('keydown', handleKeydown);
      return () => {
        window.removeEventListener('keydown', handleKeydown);
      };
    }
  }, [current]);
}

export default useKeyboardBind;
