import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import throttle from 'lodash/throttle';
import { Style } from './style';

export const ScrollTop = () => {
  const [scrollTopStatus, setScrollTopStatus] = useState(false);

  useEffect(() => {
    const clientHeight = window.innerHeight;
    const getScrollDistance = throttle(() => {
      const { scrollTop } = document.documentElement;
      const isTwiceAsMuch = scrollTop / clientHeight >= 0.5;
      console.log(scrollTop, clientHeight, isTwiceAsMuch, 'scrollTop, clientHeight, isTwiceAsMuch');
      setScrollTopStatus(isTwiceAsMuch);
    }, 200);
    window.addEventListener('scroll', getScrollDistance);

    return () => {
      window.removeEventListener('scroll', getScrollDistance);
    };
  }, []);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Style className={cn(scrollTopStatus ? 'show-scroll-top' : 'hide-scroll-top')} onClick={scrollTop}>
      <svg className='scroll-top-icon' aria-hidden='true'>
        <use xlinkHref='#iconic-dingbu' />
      </svg>
    </Style>
  );
};
