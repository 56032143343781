import styled from 'styled-components';

export default styled.div`
  .h5-preview-content {
    background-color: var(--color_body_bg);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    //overflow-x: hidden;  使用sticky时，所以父元素不能设置overflow

    .has-margin-top.quote-section + .section-footer {
      margin-top: 60px;
    }
    .empty {
      text-align: center;
      margin: ${(props) => props.theme.getSpace([90, 130, 170])};
      flex-grow: 1;

      .empty-image {
        width: ${(props) => props.theme.getSpace([132, 142, 152])};
        height: auto;
        margin-bottom: ${(props) => props.theme.getSpace(props.theme.vTextPic)};
      }
      .empty-title {
        color: #15161b;
        margin-bottom: ${(props) => props.theme.getSpace(props.theme.vText)};
      }

      .empty-desc {
        color: #969799;
      }
    }
  }
  .has-margin-top a[href=''] {
    pointer-events: none;
  }

  .preview-share-box {
    width: 592px;
    padding-bottom: 24px;
    margin-bottom: 16px;
    border-bottom: 1px solid #ebedf0;
  }

  .preview-share-box .preview-template-name {
    margin: 0 5px;
  }

  .preview-footer-btn {
    text-align: right;
  }

  .preview-bottom-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    box-sizing: border-box;
    align-items: center;
    background: #ffffff;
    box-shadow: 0 -2px 8px 0 rgba(185, 191, 201, 0.2);
  }

  .preview-menu-left {
    line-height: 14px;
    font-size: var(--base-font-14-12);
  }

  .preview-menu-status {
    color: #646566;
  }

  .preview-template-name {
    color: #15161b;
    font-weight: bold;
  }

  .preview-menu-right {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .preview-close-btn {
    margin-left: var(--pm-16-10);
    cursor: pointer;
  }

  .preview-link-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: var(--pm-16-10);

    .zent-btn {
      line-height: 40px !important;
      height: 40px !important;
    }
  }

  .preview-link-text {
    display: inline-flex;
    align-items: center;
    align-self: stretch;
    padding: 0 16px;
    background-color: #f7f8fa;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: auto;
    flex: 1;
  }

  .preview-edit-btn {
    position: fixed;
    right: 24px;
    bottom: 24px;
    width: 48px;
    height: 48px;
    background: #476cf0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;

    .icon {
      fill: #fff;
    }
  }

  /** sections整体布局 */
  @media screen and (min-width: 768px) {
    .no-margin {
      margin-top: -1px;
    }
    .has-margin-top {
      margin-top: 80px;
      margin-bottom: 80px;
    }
    .has-margin-top + .no-margin {
      margin-top: 80px;
    }
    .has-margin-bottom + .no-margin {
      margin-top: 0px;
    }
    .has-margin-bottom + .has-margin-top {
      margin-top: 0px;
    }
  }
  @media screen and (max-width: 767px) {
    .no-margin {
      margin-top: -1px !important;
    }
    .has-margin-top {
      margin-top: 16px !important;
      margin-bottom: 16px !important;
    }
    .has-margin-top + .no-margin {
      margin-top: 16px !important;
    }
    .has-margin-bottom + .no-margin {
      margin-top: 0px !important;
    }
    .has-margin-bottom + .has-margin-top {
      margin-top: 0px !important;
    }
  }
  .has-margin-top a[href=''] {
    pointer-events: none;
  }
  /** sections整体布局 */
  .no-a-jump {
    a {
      pointer-events: none;
    }
  }
`;
